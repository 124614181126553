<template>
  <div
    class="expande-horizontal centraliza"
    style="height: 100vh; background-color: #DEF2FA;"
  >
    <v-flex xs12 md8>
      <div class="expande-horizontal wrap">
        <v-card color="" class="expande-horizontal" style="min-height: 77vh;">
          <div class="expande-horizontal">
            <v-flex class="pa-6" xs12 md6>
              <div
                style="min-height: 60vh;"
                class="expande-horizontal pa-6 centraliza"
              >
                <LottieAnimation
                  v-if="loading === 'loading_rocket'"
                  class="pa-6"
                  ref="anim"
                  :loop="true"
                  :animationData="
                    require('@/apps/shared/assets/loading_rocket.json')
                  "
                />
                <LottieAnimation
                  v-if="loading === 'success'"
                  class="pa-6"
                  ref="anim2"
                  :animationData="
                    require('@/apps/shared/assets/activate_account_success.json')
                  "
                />
                <LottieAnimation
                  v-if="loading === 'man_typing'"
                  :loop="true"
                  class="pa-6 animate__fadeInUp"
                  ref="anim3"
                  :animationData="
                    require('@/apps/shared/assets/man_typing.json')
                  "
                />
              </div>
            </v-flex>
            <v-flex class="pa-6" xs12 md6>
              <v-window v-model="step">
                <v-window-item :value="1">
                  <div
                    v-if="loading === 'success'"
                    style="height: 50vh;"
                    transition="fade"
                    class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                  >
                    <v-flex class="pa-5" xs12>
                      <div class="expande-horizontal">
                        <h2 style="color: #1173ff;">
                          Conta Ativada
                        </h2>
                      </div>
                    </v-flex>
                    <v-flex class="pa-3 pr-6 pl-6" xs12>
                      <span class="fonte grey--text"
                        >Estamos quase lá, para continuar, precisamos de algumas
                        informações básicas sobre o seu negócio...</span
                      >
                      <v-flex xs12>
                        <v-btn
                          @click="goToPass2"
                          dark
                          class="fonte"
                          :color="$theme.secondary"
                          >Avançar</v-btn
                        >
                      </v-flex>
                      <clipper-fixed
                        round
                        :grid="false"
                        ratio="1"
                        src="https://img.elo7.com.br/product/zoom/27026C7/asdasd-asdad.jpg"
                        ref="clipper"
                        class="my-clipper"
                      ></clipper-fixed>
                    </v-flex>
                  </div>
                </v-window-item>
                <v-window-item :value="2">
                  <div
                    transition="fade"
                    v-if="loading === 'man_typing'"
                    style="min-height: 50vh;"
                    class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                  >
                    <v-flex class="pa-3" xs12>
                      <div class="expande-horizontal centraliza">
                        <h2 style="color: #1173ff;">
                          Abaixo
                        </h2>
                      </div>
                    </v-flex>
                    <v-flex class="pa-3 pr-6 pl-6" xs12>
                      <span class="fonte grey--text"
                        >Estamos quase lá, para continuar, precisamos de algumas
                        informações básicas sobre o seu negócio...</span
                      >
                    </v-flex>

                    <v-form ref="form2">
                      <div class="expande-horizontal wrap">
                        <v-flex xs12 class="pa-3">
                          <v-textarea
                            outlined
                            label="Descrição do seu negócio"
                            placeholder="ex: Trabalhamos com hamburgers artesanais fantásticos e sucos de primeira qualidade..."
                          ></v-textarea>
                        </v-flex>
                        <v-flex xs12 class="pa-3">
                          <v-text-field
                            outlined
                            label="Descrição"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 class="pa-3">
                          <div class="expande-horizontal">
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="goToFinish"
                              dark
                              class="fonte"
                              :color="$theme.secondary"
                              >Concluir</v-btn
                            >
                          </div>
                        </v-flex>
                      </div>
                    </v-form>
                  </div>
                </v-window-item>
              </v-window>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { clipperBasic, clipperPreview, clipperFixed } from "vuejs-clipper";

export default {
  components: {
    LottieAnimation,
    clipperBasic,
    clipperPreview,
    clipperFixed
  },
  data() {
    return {
      loading: "loading_rocket",
      step: 1
    };
  },
  props: ["id"],
  methods: {
    ...mapActions(["activateAccount"]),
    setLoading(state) {
      this.loading = state;
    },
    goToPass2() {
      this.$refs.anim2.stop();
      this.$refs.anim2.anim.playDirection = -1;
      this.$refs.anim2.anim.firstFrame = 54;
      this.$refs.anim2.play();
      console.log(this.$refs.anim2);
      setTimeout(() => {
        this.setLoading("");
      }, 300);
      setTimeout(() => {
        this.setLoading("man_typing");
        this.step = 2;
      }, 800);
    },
    goToFinish() {
      return false;
    }
  },
  mounted() {
    setTimeout(() => {
      this.setLoading("");
      setTimeout(() => {
        this.setLoading("success");
      }, 100);
    }, 2000);
    // this.activateAccount(this.id);
  }
};
</script>

<style>
.my-clipper {
  width: 250px;
  height: 250px;
}
</style>
